<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage pending alumni approval</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Pending alumni approval
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="12" md="4">
                  <v-text-field
                    class="form-control"
                    v-model="search.name"
                    label="Name, email"
                    outlined
                    clearable
                    @input="search.name = $event !== null ? $event : ''"
                    v-on:keyup.enter="getAllAlumni()"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select
                    :items="academic_years"
                    class="form-control"
                    :menu-props="{ button: true, offsetY: true }"
                    v-model="search.academic_year_id"
                    label="Academic year"
                    item-value="id"
                    item-text="year"
                    @input="search.academic_year_id = $event !== null ? $event : ''"
                    outlined
                    v-on:keyup.enter="getAllAlumni()"
                    clearable
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    outlined
                    dense
                    v-model="search.program_id"
                    :items="programs"
                    label="Programs"
                    @input="search.program_id = $event !== null ? $event : ''"
                    item-value="id"
                    :menu-props="{ button: true, offsetY: true }"
                    item-text="title"
                    v-on:keyup.enter="getAllAlumni()"
                    clearable
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="2" class="text-right">
                  <v-btn
                    @click="getAllAlumni()"
                    class="btn btn-primary w-35 btn-search"
                    :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <v-skeleton-loader type="table-thead" v-if="loading"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                  <tr class="">
                    <th class="px-3 wrap-column">Full name</th>
                    <th
                      class="px-3 wrap-column"
                    >
                      Academic info
                    </th>
                    <th class="px-3 wrap-column">Status</th>
                    <th class="pr-3 text-center wrap-column">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-show="alumni.length > 0"
                    v-for="(alumnus, index) in alumni"
                    :key="index"
                  >
                    <td class="wrap-column px-3">
                      <span class="mb-1 font-weight-medium">
                        {{ alumnus.first_name }} {{ alumnus.middle_name }} {{ alumnus.last_name }}
                      </span>
                    </td>

                    <td class="wrap-column px-3">
                      <span class="text-secondary">
                        <span class="font-weight-black">Program :</span>
                        {{ alumnus.program_title || "N/A" }} <br />
                      </span>
                      <span class="text-secondary" v-if="alumnus.academic_year">
                        <span class="font-weight-black">Academic year :</span>
                        {{ alumnus.academic_year || "N/A" }} <br />
                      </span>
                    </td>

                    <td class="px-3 wrap-column">
                      <div class="mt-2">
                        <span
                          class="badge text-lg"
                          v-bind:class="{
                            'badge-success': alumnus.is_active,
                            'badge-danger': !alumnus.is_active,
                          }"
                        >
                          {{ alumnus.is_active ? "Active" : "Inactive" }}
                        </span>
                      </div>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover">
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('alumnus', 'edit')">
                              <a class="navi-link" @click="editAlumnus(alumnus.id)">
                                <span class="navi-icon">
                                  <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('alumnus', 'review')">
                              <a @click="reviewAlumnus(alumnus)" class="navi-link">
                                <span class="navi-icon">
                                  <i
                                    class="fas fa-info-circle"
                                    style="color: #00008b"
                                  ></i>
                                </span>
                                <span class="navi-text">Review alumnus</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('alumnus', 'delete')">
                              <a class="navi-link" @click="deleteAlumnus(alumnus.id)">
                                <span class="navi-icon">
                                  <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-show="alumni.length == 0">
                    <td class="text-center" colspan="7">
                      <strong>No data available to display.</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-pagination
                v-if="alumni.length > 0"
                class="pull-right mt-7"
                @input="getAllAlumni"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                :loading="loading"
                last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <v-dialog
        v-model="dialog"
        max-width="800px"
        transition="dialog-bottom-transition"
        scrollable
      >
        <v-card v-if="alumnusDetail">
          <v-card-title>
            <span class="h5 font-weight-medium"
              >This alumnus was created on
              <strong>{{ alumnusDetail.created_date }}</strong> by
              <strong>{{ alumnusDetail.full_name }}</strong></span
            ><br />
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <span
                    v-if="alumnusDetail.email && alumnusDetail.email != ''"
                    class="font-weight-bold subtitle-1"
                  >
                    Email:</span
                  >&nbsp;
                  <span class="font-weight-medium subtitle-1">{{
                    alumnusDetail.email
                  }}</span>
                </v-col>
                <v-col cols="12">
                  <span
                    v-if="alumnusDetail.program_title"
                    class="font-weight-bold subtitle-1"
                  >
                    Program:</span
                  >&nbsp;
                  <span class="font-weight-medium subtitle-1">{{
                    alumnusDetail.program_title
                  }}</span>
                </v-col>
                <v-col cols="12">
                  <span
                    v-if="alumnusDetail.academic_year"
                    class="font-weight-bold subtitle-1"
                  >
                    Academic year:</span
                  >&nbsp;
                  <span class="font-weight-medium subtitle-1">{{
                    alumnusDetail.academic_year
                  }}</span>
                </v-col>
                <v-col cols="12">
                  <ckeditor :config="editorConfig" v-model="alumnus.approved_comment">
                  </ckeditor>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn text class="cancel-btn" x-large @click="closeDialog"> Close </v-btn>
            <v-btn
              dark
              color="red"
              x-large
              :loading="buttonLoadingDisapprove"
              @click="disapprovedAlumnus"
              rounded
              v-if="checkIsAccessible('alumnus', 'disapproval')"
            >
              <v-icon right dark small> fas fa-times </v-icon> Not approve
            </v-btn>
            <v-btn
              dark
              x-large
              :loading="buttonLoadingApprove"
              @click="approvedAlumnus"
              rounded
              v-if="checkIsAccessible('alumnus', 'approval')"
            >
              <v-icon right small dark> fas fa-check </v-icon> Approve
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>
<script>
import AlumnusService from "@/core/services/alumnus/AlumnusService";
import ProgramService from "@/core/services/level/program/ProgramService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
const academicYearService = new AcademicYearService();
const program = new ProgramService();
const alumnus = new AlumnusService();
export default {
  data() {
    return {
      loading: false,
      alumni: [],
      search: {
        name: "",
        program_id: null,
        academic_year_id: null,
        approved_status: "pending",
      },
      page: null,
      total: 0,
      totalPage: null,
      perPage: 0,
      dialog: false,
      alumnusDetail: null,
      alumnus: {
        approved_comment: null,
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [
          [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "RemoveFormat",
            "Link",
            "NumberedList",
            "BulletedList",
          ],
        ],
      },
      buttonLoadingApprove: false,
      buttonLoadingDisapprove: false,
      programLoading: false,
      academicYearLoading: false,
      programs: [],
      academic_years: [],
    };
  },
  methods: {
    getAllAlumni() {
      this.loading = true;
      alumnus
        .paginate(this.search, this.page)
        .then((response) => {
          this.alumni = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.totalPage = response.data.meta.last_page;
          this.perPage = parseInt(response.data.meta.per_page);
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },

    editAlumnus(alumnusId) {
      this.$router.push({
        name: "alumnus-edit",
        params: { alumnusId: alumnusId },
      });
    },
    deleteAlumnus(alumnusId) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            alumnus
              .delete(alumnusId)
              .then((response) => {
                this.$snotify.success("Information deleted");
                this.getAllAlumni();
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        },
      });
    },
    openSummaryPage(item) {
      this.$router.push({
        name: "students-summary",
        params: { id: item.user_id, type: "student" },
      });
    },
    reviewAlumnus(item) {
      this.alumnusDetail = item;
      this.dialog = true;
    },
    closeDialog() {
      this.alumnusDetail = null;
      this.dialog = false;
      this.alumnus = null;
      this.alumnus = {
        approved_comment: null,
      };
    },
    disapprovedAlumnus() {
      this.buttonLoadingDisapprove = true;
      this.alumnus.approved_status = "rejected";
      this.alumnus.is_rejected = 1;
      this.alumnus.approved_by = this.currentUser.id;
      alumnus
        .update(this.alumnusDetail.id, this.alumnus)
        .then((response) => {
          this.$snotify.success("Alumnus disapproved");
          this.closeDialog();
          this.getAllAlumni();
        })
        .catch((err) => {})
        .finally(() => {
          this.buttonLoadingDisapprove = false;
        });
    },
    approvedAlumnus() {
      this.buttonLoadingApprove = true;
      this.alumnus.approved_status = "approved";
      this.alumnus.approved_by = this.currentUser.id;
      this.alumnus.is_approved = 1;
      alumnus
        .update(this.alumnusDetail.id, this.alumnus)
        .then((response) => {
          this.$snotify.success("Alumnus approved");
          this.closeDialog();
          this.getAllAlumni();
        })
        .catch((err) => {})
        .finally(() => {
          this.buttonLoadingApprove = false;
        });
    },
    getAllPrograms() {
      this.programLoading = true;
      program
        .all()
        .then((response) => {
          this.programs = response.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.programLoading = false;
        });
    },
    getAcademicYears() {
      this.academicYearLoading = true;
      academicYearService
        .all()
        .then((response) => {
          this.academic_years = response.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.academicYearLoading = false;
        });
    },
  },
  mounted() {
    this.getAllPrograms();
    this.getAcademicYears();
    this.getAllAlumni();
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
};
</script>
